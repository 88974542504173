/**
 * Do not edit directly
 * Generated on Fri Apr 19 2024 09:27:16 GMT+0000 (Coordinated Universal Time)
 */

export const COLORS_KEYS = [
  'gray-50',
  'gray-100',
  'gray-200',
  'gray-300',
  'gray-400',
  'gray-500',
  'gray-600',
  'gray-700',
  'gray-800',
  'gray-900',
  'gray-1000',
  'gray-1100',
  'gray-000',
  'neutral-50',
  'neutral-100',
  'neutral-200',
  'neutral-300',
  'neutral-400',
  'neutral-500',
  'neutral-600',
  'neutral-700',
  'neutral-800',
  'neutral-900',
  'neutral-1000',
  'neutral-1100',
  'neutral-000',
  'indigo-50',
  'indigo-100',
  'indigo-200',
  'indigo-300',
  'indigo-400',
  'indigo-500',
  'indigo-600',
  'indigo-700',
  'indigo-800',
  'indigo-900',
  'indigo-1000',
  'indigo-1100',
  'navy-50',
  'navy-100',
  'navy-200',
  'navy-300',
  'navy-400',
  'navy-500',
  'navy-600',
  'navy-700',
  'navy-800',
  'navy-900',
  'navy-1000',
  'navy-1100',
  'critical-50',
  'critical-100',
  'critical-200',
  'critical-300',
  'critical-400',
  'critical-500',
  'critical-600',
  'critical-700',
  'critical-800',
  'critical-900',
  'critical-1000',
  'critical-1100',
  'success-50',
  'success-100',
  'success-200',
  'success-300',
  'success-400',
  'success-500',
  'success-600',
  'success-700',
  'success-800',
  'success-900',
  'success-1000',
  'success-1100',
  'warning-50',
  'warning-100',
  'warning-200',
  'warning-300',
  'warning-400',
  'warning-500',
  'warning-600',
  'warning-700',
  'warning-800',
  'warning-900',
  'warning-1000',
  'warning-1100',
  'info-50',
  'info-100',
  'info-200',
  'info-300',
  'info-400',
  'info-500',
  'info-600',
  'info-700',
  'info-800',
  'info-900',
  'info-1000',
  'info-1100',
  'teal-50',
  'teal-100',
  'teal-200',
  'teal-300',
  'teal-400',
  'teal-500',
  'teal-600',
  'teal-700',
  'teal-800',
  'teal-900',
  'teal-1000',
  'teal-1100',
  'pink-50',
  'pink-100',
  'pink-200',
  'pink-300',
  'pink-400',
  'pink-500',
  'pink-600',
  'pink-700',
  'pink-800',
  'pink-900',
  'pink-1000',
  'pink-1100',
  'orange-50',
  'orange-100',
  'orange-200',
  'orange-300',
  'orange-400',
  'orange-500',
  'orange-600',
  'orange-700',
  'orange-800',
  'orange-900',
  'orange-1000',
  'orange-1100',
  'purple-50',
  'purple-100',
  'purple-200',
  'purple-300',
  'purple-400',
  'purple-500',
  'purple-600',
  'purple-700',
  'purple-800',
  'purple-900',
  'purple-1000',
  'purple-1100',
  'green-50',
  'green-100',
  'green-200',
  'green-300',
  'green-400',
  'green-500',
  'green-600',
  'green-700',
  'green-800',
  'green-900',
  'green-1000',
  'green-1100',
  'coral-50',
  'coral-100',
  'coral-200',
  'coral-300',
  'coral-400',
  'coral-500',
  'coral-600',
  'coral-700',
  'coral-800',
  'coral-900',
  'coral-1000',
  'coral-1100',
  'blue-50',
  'blue-100',
  'blue-200',
  'blue-300',
  'blue-400',
  'blue-500',
  'blue-600',
  'blue-700',
  'blue-800',
  'blue-900',
  'blue-1000',
  'blue-1100',
]
